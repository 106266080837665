import "./BarbersBlock.scss";

const BarbersBlock = (props) => {
    const {barbers} = props;
    return (
        <section id="barbers" className="barbers-block">
            <div className="barbers-block__wrapper">
                <div className="barbers-block__title">
                    <h2>Майстри</h2>
                </div>
                <div className="barbers-block__list">
                    {barbers?.map((val, i) =>
                        <div className="barber-item" key={i}>
                            <div className="barber-item__img">
                                <img src={val?.avatar_big}/>
                            </div>
                            <p className="barber-item__name">{val?.name}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default BarbersBlock;