
export const fetchBarbers = async (companyID, token) => {
    return await fetch(`https://api.alteg.io/api/v1/book_staff/${companyID}`, {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(response => response.json());
}

export const fetchServices = async (companyID, token) => {
    return await fetch(`https://api.alteg.io/api/v1/book_services/${companyID}`, {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(response => response.json());
}