import React from "react";
import Header from "./components/header/Header";
import WelcomeBlock from "./components/welcome-block/WelcomeBlock";
import ServicesBlock from "./components/services-block/ServicesBlock";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import ContactsBlock from "./components/contacts-block/ContactsBlock";
import ScrollToHashElement from "./components/scroll-to-hash-element/ScrollToHashElement";
import BarbersBlock from "./components/barbers-block/BarbersBlock";
import * as XLSX from 'xlsx';
import './App.scss';
import * as apiService from "./service/altegio-api-service";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {

        const token = "gtcwf654agufy25gsadh";
        const companyID = "1182098";

        const workbook = await fetch("/cross-settings.xlsx")
            .then(resp => resp.blob())
            .then(blob => blob.arrayBuffer())
            .then(buffer => XLSX.read(buffer, {type: "buffer"}));

        let barbers = await apiService.fetchBarbers(companyID, token);
        let services = await apiService.fetchServices(companyID, token);

        const servicesData = services.category.map(cat => ({
            id: cat.id,
            title: cat.title,
            services: services.services
                .filter(service => service.category_id === cat.id)
                .map(service => ({title: service.title, price: service.price_min}))
        }));

        this.setState({...this.state, apiData: {barbers, services: servicesData}});


        this.processWorkbook(workbook)
    }

    processWorkbook(workbook) {

        var data = {...this.state};

        Object.entries(workbook.Sheets).forEach(([sheetName, sheet]) => {
            let sheetData;
            if (sheetName === "welcome") {
                sheetData = {};
                XLSX.utils.sheet_to_json(sheet, {header: "A"}).forEach(obj => {
                    sheetData = {...sheetData, [obj["A"]]: obj["B"]};
                })
            } else {
                sheetData = XLSX.utils.sheet_to_json(sheet)
            }
            data = {...data, [sheetName]: sheetData};
        })

        this.setState(data);
    }


    render() {
        const {welcome, products, contacts, social, apiData} = this.state;
        return (
            <>
                <ScrollToHashElement/>
                <Header/>
                <WelcomeBlock welcome={welcome} contacts={contacts} social={social}/>
                <ServicesBlock services={apiData?.services}/>
                <BarbersBlock barbers={apiData?.barbers}/>
                {/*<ProductsBlock products={products}/>*/}
                <ContactsBlock contacts={contacts} social={social}/>
            </>
        );
    }
}

const saveFile = async (blob) => {
    const a = document.createElement('a');
    a.download = 'my-file.xlsx';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export default App;
library.add(fab, fas, far)
