import "./Header.scss";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <header className="header">
            <nav className="header__nav">
                <Link scroll="top" to="/#services" className="services">
                    <div className="header__link">
                        <div className="header__link-img services__img">
                            <img className="service-1" src="/service-1.svg" alt="" draggable="false"/>
                            <img className="service-2" src="/service-2.svg" alt="" draggable="false"/>
                        </div>
                        <span className="header__link-label">
                            Послуги
                        </span>
                    </div>
                </Link>
                <Link to="/#barbers">
                    <div className="header__link barbers">
                        <div className="header__link-img barbers__img">
                            <img className="barber-1" src="/barber-1.svg" alt="" draggable="false"/>
                            <img className="barber-2" src="/barber-2.svg" alt="" draggable="false"/>
                        </div>
                        <span className="header__link-label">
                            Майстри
                        </span>
                    </div>
                </Link>

                <div className="header__logo">
                    <img className="logo__img" src="/logo.png" alt="" draggable="false"/>
                </div>

                <Link to="/#products">
                    <div className="header__link products">
                        <div className="header__link-img products__img">
                            <div className="product-top">
                                <img className="product-3" src="/products-3.svg" alt="" draggable="false"/>
                                <img className="product-1" src="/products-1.svg" alt="" draggable="false"/>
                            </div>
                            <img className="product-2" src="/products-2.svg" alt="" draggable="false"/>
                        </div>
                        <span className="header__link-label">
                            Продукція
                        </span>
                    </div>
                </Link>
                <Link to="/#contacts">
                    <div className="header__link contacts">
                        <div className="header__link-img contacts__img">
                            <img className="contact-1" src="/contacts-1.svg" alt="" draggable="false"/>
                            <img className="contact-2" src="/contacts-2.svg" alt="" draggable="false"/>
                        </div>
                        <span className="header__link-label">
                            Контакти
                        </span>
                    </div>
                </Link>
            </nav>
        </header>
    )
}

export default Header;
