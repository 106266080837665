import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import L from 'leaflet';
import "./ContactsBlock.scss";

import icon from '../../assets/map-marker.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef} from "react";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [41, 41],
    iconAnchor: [21, 41],
    popupAnchor: [0, -40]
});

L.Marker.prototype.options.icon = DefaultIcon;
const ContactsBlock = (props) => {
    const {contacts, social} = props;
    const map = React.useRef();
    const refsContacts = contacts?.map((val, i) => ({...val, coordinates: JSON.parse(val.coordinates), ref: createRef()}));

    let sum = [0, 0];
    refsContacts?.forEach(contact => {
        sum[0] += contact.coordinates[0];
        sum[1] += contact.coordinates[1];
    });

    let mapStartPosition = [50.450253, 30.523752];
    let mapZoom = 11;

    if (!!refsContacts?.length) {
        mapStartPosition = [sum[0] / refsContacts.length, sum[1] / refsContacts.length];
        map.current?.flyTo(mapStartPosition);
    }




    return (
        <section id="contacts" className="contacts-block">
            <div className="contacts-block__wrapper">
                <div className="contacts-block__title">
                    <h2>Контакти</h2>
                </div>

                <div className="contacts-block__list">
                    {refsContacts?.map((val, i) =>
                        <div className="contact-item" key={i}>
                            <div className="contact-item__address" onClick={() => {
                                map.current?.flyTo(val?.coordinates, 13)
                                val?.ref?.current.openPopup();
                            }}>
                                {val?.address}
                            </div>
                            <div className="contact-item__phone">
                                <a href={"tel:" + val?.phone?.replace(/ /g, '').replace(/-/g, '')}>
                                    <FontAwesomeIcon icon="fa-solid fa-phone" className="icon"/>
                                    {val?.phone}
                                </a>
                            </div>
                        </div>
                    )}

                </div>

                <div className="contacts-block__social">
                    {social?.map((val, i) =>
                        <a key={i} href={val?.link}>
                            <FontAwesomeIcon className="icon" icon={val?.icon}/>
                            <span className="text">{val?.label}</span>
                        </a>
                    )}
                </div>
            </div>
            <div className="map">
                <MapContainer center={mapStartPosition} zoomControl={false} zoom={mapZoom} minZoom={6} maxZoom={16}
                              scrollWheelZoom={true} ref={map}>
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                    />
                    {refsContacts?.map((val, i) =>
                        <Marker key={i} position={val?.coordinates} ref={val?.ref}>
                            <Popup>
                                <div className="popup-container">
                                    <div className="popup-container__info">
                                        <p className="popup-container__address">
                                            {val?.address}
                                        </p>
                                        <a href={"tel:" + val?.phone?.replace(/ /g, '').replace(/-/g, '')} className="popup-container__phone">
                                            <FontAwesomeIcon icon="fa-solid fa-phone" className="icon"/>
                                            {val?.phone}
                                        </a>
                                    </div>
                                    <div className="popup-container__link">
                                        <a href={val?.map_link} target="_blank" rel="noreferrer">
                                            <img src="/google-maps.svg"/>
                                            <span>Відкрити в <br/> Google Maps</span>
                                        </a>
                                    </div>
                                </div>

                            </Popup>
                        </Marker>
                    )}
                </MapContainer>
            </div>

        </section>
    );
}

export default ContactsBlock;