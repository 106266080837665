import "./ServicesBlock.scss"

const ServicesBlock = (props) => {
    const {services} = props;
    return (
        <section id="services" className="service-block">
            <div className="service-block__wrapper">
                <div className="service-block__title">
                    <h2>Послуги</h2>
                </div>
                <div className="service-block__list">
                    {services?.map((category, i) =>
                        <div className="service-category" key={i}>
                            <p className="service-category__title">{category?.title}</p>
                            {category?.services?.map((service, j) =>
                                <p className="service-item" key={i + '-' + j}>
                                    <span className="service-item__name">{service?.title}</span>
                                    <span className="service-item__dotted-space"></span>
                                    <span className="service-item__amount">
                                    <span className="service-item__price">{service?.price}</span>
                                    <span className="service-item__currency">₴</span>
                                </span>
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ServicesBlock;
